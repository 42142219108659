import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./App.css";
import PostPakket from "./assets/post-pakket-1.png";
import { Input, InputColor } from "./components/Input";
import { Loader } from "./components/Loader";
import { Navbar } from "./components/Menu/Navbar";
import { reactPlugin } from "./insights/applicationInsights";
import withTracking from "./insights/exportWithTracking";

function Home() {
  const { t } = useTranslation("home");
  const navigate = useNavigate();
  const [trackNumber, setTrackNumber] = useState("");
  const [zipcode, setZipcode] = useState("");

  const [errorTrackNumber, setErrorTrackNumber] = useState("");
  const [errorZipcode, setErrorZipcode] = useState("");
  const [loading, setLoading] = useState(false);

  const setTrackHandler = () => {
    setLoading(true);
    if (!trackNumber) {
      setErrorTrackNumber(t("errors.trackNumber").toString());
    }
    if (!zipcode) {
      setErrorZipcode(t("errors.zipcode").toString());
    }
    if (trackNumber && zipcode) {
      // window.open(`/${trackNumber}/${zipcode}`); // Open in new tab
      navigate(`/${trackNumber}/${zipcode}`);
    }
    setLoading(false);
  };

  return (
    <div
      className={
        "flex flex-col w-full h-full bg-background text-text min-h-screen relative"
      }
    >
      <div className={"grid grid-cols-12 w-full bg-white pb-4"}>
        <div className={"container col-span-12 h-fit"}>
          <Navbar />
        </div>
      </div>
      <div className={"md:container pt-8 mb-12"}>
        <div className={"mx-auto max-w-xl bg-white p-8"}>
          <div className={"flex flex-row mb-4"}>
            <div className={"h-20 w-20"}>
              <img src={PostPakket} alt="Track & Trace" />
            </div>
            <div className={"ml-3 my-auto"}>
              <div className={"text-xl font-semibold"}>
                {t("title").toString()}
              </div>
              <div className={"text-md"}>{t("subTitle").toString()}</div>
            </div>
          </div>

          <form onSubmit={(e) => e.preventDefault()}>
            <div
              className={`w-full ${errorTrackNumber ? "mb-7" : "mb-2.5"} h-12`}
            >
              <Input
                placeholder={t("form.trackNumber").toString()}
                color={InputColor.DARK_GREY}
                error={!!errorTrackNumber}
                errorMessage={errorTrackNumber}
                passValueChange={setTrackNumber}
                onChange={() => setErrorTrackNumber("")}
              />
            </div>
            <div className={`w-full ${errorZipcode ? "mb-7" : "mb-2.5"} h-12`}>
              <Input
                placeholder={t("form.zipcode").toString()}
                color={InputColor.DARK_GREY}
                error={!!errorZipcode}
                errorMessage={errorZipcode}
                passValueChange={setZipcode}
                onChange={() => setErrorZipcode("")}
              />
            </div>
            <button
              className={
                "relative flex items-center bg-primary rounded-md cursor-pointer p-3 px-3 w-full"
              }
              onClick={setTrackHandler}
            >
              <p className="text-white text-center w-full">
                {t("form.button").toString()}
              </p>
              {loading && (
                <div
                  className={
                    "absolute left-0 w-full h-full rounded-md bg-primary"
                  }
                >
                  <div className={"flex justify-center w-8 h-full mx-auto"}>
                    <Loader />
                  </div>
                </div>
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default withTracking(reactPlugin, Home);
