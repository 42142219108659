import classNames from "classnames";
import { useState } from "react";
import { Loader } from "../Loader";

export enum ButtonTypeEnum {
    PRIMARY = "primary", WHITE = "white", OUTLINE = "outline", DEFAULT = "default"
}

export interface ButtonProps {
    buttonType: ButtonTypeEnum,
    text: string,
    onClick?: (...args : any[]) => (void | Promise<any>),
    onAsyncClick?: (...args : any[]) => Promise<any>,
    disabled?: boolean,
}

interface ButtonRenderProps extends ButtonProps {
    loading: boolean,
    setLoading: (value: boolean) => void
}

const buttonRender = (button: ButtonRenderProps) => (
    <button className={classNames(
        "w-full h-full border-1 cursor-pointer disabled:opacity-75 disabled:cursor-not-allowed",
        {
            "text-white bg-primary border-primary rounded-sm": button.buttonType === ButtonTypeEnum.PRIMARY,
        },
        {
            "text-primary bg-white border-white rounded-sm": button.buttonType === ButtonTypeEnum.WHITE,
        },
        {
            "text-primary bg-white border-primary rounded-sm": button.buttonType === ButtonTypeEnum.OUTLINE,
        },
        {
            "text-white text-sm bg-green border-green rounded-md": button.buttonType === ButtonTypeEnum.DEFAULT
        }
    )}
        onClick={async () => {
            if (button.onClick) button.onClick();
            if (button.onAsyncClick) {
                button.setLoading(true);
                await button.onAsyncClick().then(() => button.setLoading(false));
            }
        }}
        disabled={button.disabled}
    >
        {button.text}
    </button>
)

const Button = ({ buttonType, text, onClick, onAsyncClick, disabled = false }: ButtonProps) => {
    const [loading, setLoading] = useState(false);

    return (
        <>
            {loading ? (
                <div className={classNames(
                    "absolute flex justify-center items-center w-full h-full border-1 rounded-sm",
                    {
                        "bg-primary border-primary": buttonType === ButtonTypeEnum.PRIMARY,
                    },
                    {
                        "bg-white border-white": buttonType === ButtonTypeEnum.WHITE,
                    },
                    {
                        "bg-white border-primary": buttonType === ButtonTypeEnum.OUTLINE,
                    },
                    {
                        "bg-green border-green": buttonType === ButtonTypeEnum.DEFAULT
                    }
                )}>
                    <div className={"w-7 h-7"}>
                        <Loader />
                    </div>
                </div>
            ) : null}
            {buttonRender({ buttonType, text, onClick, onAsyncClick, disabled, loading, setLoading })}
        </>
    )
}

export { Button }
