import { TFunction } from "i18next";
import { ReactComponent as CreatedIcon } from "../../assets/created.svg";
import { ReactComponent as CrossIcon } from "../../assets/cross.svg";
import { ReactComponent as DeliveredIcon } from "../../assets/delivered.svg";
import { ReactComponent as HomeIcon } from "../../assets/home.svg";
import { ReactComponent as HubIcon } from "../../assets/hub.svg";
import { ReactComponent as LockerIcon } from "../../assets/locker.svg";
import { ReactComponent as TransitIcon } from "../../assets/transit.svg";
import { ReactComponent as UserIcon } from "../../assets/user-regular.svg";
import { ProgressStep } from "../../components/Track";
import { DeliveryPreferences } from "../../components/Track/Preferences";
import { StatusLabel } from "../../components/Track/StatusLabel";
import { colors } from "../global/constants";

export const getOrdinalNumber = (num: number) => {
  if (num > 3 && num < 21) return `${num}th`;
  switch (num % 10) {
    case 1:
      return `${num}st`;
    case 2:
      return `${num}nd`;
    case 3:
      return `${num}rd`;
    default:
      return `${num}th`;
  }
};

export const getStepByStatus = (status: string) => {
  switch (status) {
    case "Created":
      return 0;
    case "CreatedAtShippingProvider":
      return 0;
    case "PlannedForDistribution":
      return 0;
    case "SortedForDistribution":
      return 1;
    case "SortedForDelivery":
      return 2;
    case "PlannedForDelivery":
      return 2;
    case "Loaded":
      return 2;
    case "NotDelivered":
      return 2;
    case "DistributionDelayed":
      return 2;
    case "Rerouting":
      return 2;
    case "ResortedForDistribution":
      return 2;
    case "Delivered":
      return 3;
    case "DeliveryDelayed":
      return 2;
    case "DataflowIssue":
      return 2;
    case "Retour":
      return 4;
    case "RetourToDistribution":
      return 4;
    case "RetourToCustomer":
      return 4;
    case "RetourFinal":
      return 4;
    case "Cancelled":
      return 5;
    default:
      return -1;
  }
};

export const getStatusStepByStatus = (status: string) => {
  switch (status) {
    case "ShipmentCreated":
      return 0;
    case "ShipmentCancelled":
      return 0;
    case "ShipmentPlanned":
      return 1;
    case "PackagePlannedForDelivery":
      return 1;
    case "ShipmentPlannedForDelivery":
      return 1;
    case "PackageReadyForProcessingAtDistribution":
      return 1;
    case "ShipmentReadyForProcessingAtDistribution":
      return 1;
    case "PackageLoaded":
      return 2;
    case "ShipmentLoaded":
      return 2;
    case "LabelScanned":
      return 2;
    case "PackageSorted":
      return 1;
    case "PackageSortedForDistribution":
      return 1;
    case "ShipmentSortedForDistribution":
      return 1;
    case "PackageSortedForDelivery":
      return 1;
    case "ShipmentSortedForDelivery":
      return 1;
    case "PackageNotDelivered":
      return 1;
    case "ShipmentNotDelivered":
      return 1;
    case "PackageRetour":
      return 1;
    case "ShipmentRetour":
      return 1;
    case "CustomerRequest":
      return 1;
    case "PackageDelivered":
      return 3;
    case "ShipmentDelivered":
      return 3;
    case "DistributionDelayed":
      return 2;
    case "Rerouting":
      return 2;
    case "ResortedForDistribution":
      return 2;
    case "DeliveryDelayed":
      return 3;
    case "DataflowIssue":
      return 3;
    case "RetourToDistribution":
      return 4;
    case "RetourToCustomer":
      return 4;
    case "RetourFinal":
      return 4;
    default:
      return -1;
  }
};

export const validateBarcode = (barcode: string) => {
  return barcode.match(/[0-9]{6}/g);
};

export const validateZipcode = (zipcode: string) => {
  return zipcode.match(/^[1-9][0-9]{3,}( )?[A-Z]*$/i);
};

export const renderIcon = (step: string, color: string) => {
  switch (step) {
    case ProgressStep.CREATED:
      return <CreatedIcon fill={color} />;
    case ProgressStep.HUB:
      return <HubIcon fill={color} />;
    case ProgressStep.TRANSIT:
      return <TransitIcon fill={color} />;
    // case ProgressStep.LOCKER:
    //     return (
    //         <LockerIcon fill={color} />
    //     );
    case ProgressStep.DELIVERED:
      return <DeliveredIcon fill={color} />;
    case ProgressStep.RETURNED:
      return (
        <CrossIcon
          className={"p-1"}
          fill={color}
          width={"100%"}
          height={"100%"}
        />
      );
    default:
      return <UserIcon fill={color} className={"p-0.5"} />;
  }
};

export const renderStatusLabel = (step: string) => {
  switch (step) {
    case ProgressStep.CREATED:
      return <StatusLabel type={ProgressStep.CREATED} />;
    case ProgressStep.HUB:
      return <StatusLabel type={ProgressStep.HUB} />;
    case ProgressStep.TRANSIT:
      return <StatusLabel type={ProgressStep.TRANSIT} />;
    // case ProgressStep.LOCKER:
    //     return (
    //         <LockerIcon fill={color} />
    //     );
    case ProgressStep.DELIVERED:
      return <StatusLabel type={ProgressStep.DELIVERED} />;
    case ProgressStep.RETURNED:
      return <StatusLabel type={ProgressStep.RETURNED} />;
    case ProgressStep.CANCELLED:
      return <StatusLabel type={ProgressStep.CANCELLED} />;
  }
};

export const renderStepHeader = (step: string, t: TFunction) => {
  switch (step) {
    case ProgressStep.CREATED:
      return t("steps.created.header").toString();
    case ProgressStep.HUB:
      return t("steps.hub.header").toString();
    case ProgressStep.TRANSIT:
      return t("steps.transit.header").toString();
    // case ProgressStep.LOCKER:
    //     return t("steps.locker.header").toString();
    case ProgressStep.DELIVERED:
      return t("steps.delivered.header").toString();
    case ProgressStep.RETURNED:
      return t("steps.returned.header").toString();
    case ProgressStep.CANCELLED:
      return t("steps.cancelled.header").toString();
  }
};

export const renderStepStatusHeader = (status: string, t: TFunction) => {
  switch (status) {
    case "ShipmentCreated":
      return t("status.shipmentCreated.header").toString();
    case "ShipmentCancelled":
      return t("status.shipmentCancelled.header").toString();
    case "PackageLoaded":
      return t("status.packageLoaded.header").toString();
    case "LabelScanned":
      return t("status.labelScanned.header").toString();
    case "PackageSorted":
      return t("status.packageSorted.header").toString();
    case "PackageDelivered":
      return t("status.packageDelivered.header").toString();
    case "ShipmentDelivered":
      return t("status.shipmentDelivered.header").toString();
    default:
      return "";
  }
};

export const renderPreferenceIcon = (preference: string) => {
  switch (preference) {
    case DeliveryPreferences.HOME:
      return <HomeIcon fill={colors.primary} />;
    // case DeliveryPreferences.LOCKER:
    //     return <LockerIcon fill={colors.primary} />
    case DeliveryPreferences.PICKUP:
      return <LockerIcon fill={colors.primary} />;
  }
};
