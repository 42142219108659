import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});
if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
  appInsights.loadAppInsights();
}

export { reactPlugin, appInsights };