import { MenuList } from "../index";
import { Button, ButtonTypeEnum } from "../../../Button";
import { useTranslation } from "react-i18next";
import { useStoreActions } from "../../../../store/hooks";

interface MenuItemProps {
    items: MenuList[]
}

const MenuItems = ({ items }: MenuItemProps) => {
    return (
        <ul className="flex items-center gap-9">
            {items.map((item, index) =>
                <li key={index}>
                    <a href={item.link}>{item.text}</a>
                </li>
            )}
        </ul>
    )
}

export { MenuItems }
