import classNames from "classnames";
import { colors } from "../../../service/global/constants";
import { renderIcon } from "../../../service/track";
import { ProgressStep } from "../index";

interface ProgressProps {
  step: number;
}

const Progress = ({ step }: ProgressProps) => {
  return (
    <div className={"flex justify-start"}>
      {Object.keys(ProgressStep).map((progressStep, index) => {
        if (
          progressStep !== ProgressStep.RETURNED &&
          progressStep !== ProgressStep.CANCELLED
        )
          return (
            <div className="flex" key={index}>
              <div
                className={classNames(
                  "flex items-center rounded-full border-2",
                  {
                    "border-black": index <= step,
                  },
                  {
                    "border-grey":
                      index > step ||
                      Object.keys(ProgressStep)[step] ===
                        ProgressStep.CANCELLED,
                  }
                )}
              >
                <div
                  className={
                    "flex justify-center items-center w-12 xs:w-13 sm:w-15 h-12 xs:h-13 sm:h-15 p-3"
                  }
                >
                  {/* If package has reached the returned step, render a a different icon for step 4 */}
                  {renderIcon(
                    progressStep === ProgressStep.DELIVERED &&
                      Object.values(ProgressStep)[step] ===
                        ProgressStep.RETURNED
                      ? ProgressStep.RETURNED
                      : progressStep,
                    index <= step &&
                      Object.keys(ProgressStep)[step] !== ProgressStep.CANCELLED
                      ? colors.green
                      : colors.grey
                  )}
                </div>
              </div>
              {index < Object.keys(ProgressStep).length - 3 ? (
                <div className="flex w-2.5 md:w-5 align-center align-middle items-center content-center">
                  <div className="w-full h-0.5 bg-black" />
                </div>
              ) : null}
            </div>
          );
      })}
    </div>
  );
};

export { Progress };
