import i18next from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ShipmentType } from "../../models/track.dto";
import { getLocalisedDate } from "../../service/steps";
import { getStepByStatus, renderStatusLabel } from "../../service/track";
import { useStoreActions, useStoreState } from "../../store/hooks";
import { Contact } from "../Contact";
import { Overlay } from "../Overlay";
import { Progress } from "./Progress";
import { Steps } from "./Steps";

export enum ProgressStep {
  CREATED = "CREATED",
  HUB = "HUB",
  TRANSIT = "TRANSIT",
  DELIVERED = "DELIVERED",
  //PICKEDUP = "PICKEDUP",
  RETURNED = "RETURNED",
  CANCELLED = "CANCELLED",
}

const Track = () => {
  const { track } = useStoreState((state) => state.PackageModel);

  const { t } = useTranslation(
    track.shipmentType === ShipmentType.RETOUR
      ? "components-return"
      : "components"
  );
  const locale = i18next.language;

  const { showContact } = useStoreState((state) => state.AppModel);
  const { setShowContact } = useStoreActions((actions) => actions.AppModel);

  const packageInfo = track.shipmentInformation;
  const arrivalDateMinimum = getLocalisedDate(
    new Date(track.estimatedDeliveryWindow?.minimum)
  );
  const arrivalDateMaximum = getLocalisedDate(
    new Date(track.estimatedDeliveryWindow?.maximum)
  );

  const deliveredOnUtc = getLocalisedDate(new Date(track.deliveredOnUtc));

  const [step] = useState(getStepByStatus(track.status));

  return (
    <div className={"flex flex-col gap-3 p-7"}>
      {/* Status label */}
      {renderStatusLabel(
        Object.values(ProgressStep)[getStepByStatus(track.status)]
      )}
      {/* Track Header */}
      <div className={"flex flex-col mt-1"}>
        <div className={"uppercase font-bold"}>
          {t("summaryblock.package").toString()} {packageInfo?.name}
        </div>

        {track.status !== "Retour" &&
        track.estimatedDeliveryWindow !== null &&
        track.estimatedDeliveryWindow?.minimum &&
        track.estimatedDeliveryWindow?.maximum ? (
          <>
            <div className={"text-2xl"}>
              {arrivalDateMinimum.toLocaleDateString(locale, {
                weekday: "long",
                month: "long",
                day: "numeric",
              })}
            </div>
            <div className={"text-xl"}>
              {`${t(
                "track.between"
              ).toString()} ${arrivalDateMinimum.toLocaleString(locale, {
                hour: "numeric",
                minute: "numeric",
                hour12: false,
              })}`}{" "}
              {`${t(
                "track.and"
              ).toString()} ${arrivalDateMaximum.toLocaleString(locale, {
                hour: "numeric",
                minute: "numeric",
                hour12: false,
              })}`}
              {/* {t("track.temp").toString()} */}
            </div>
          </>
        ) : (
          <>
            <div className={"text-2xl"}>
              {Object.values(ProgressStep)[step] === ProgressStep.RETURNED
                ? t("track.retour").toString()
                : Object.keys(ProgressStep)[step] === ProgressStep.DELIVERED
                ? t("track.afterDelivery").toString()
                : Object.keys(ProgressStep)[step] === ProgressStep.CANCELLED
                ? t("track.cancelled")
                : t("track.tbd").toString()}
            </div>
            {Object.keys(ProgressStep)[step] === ProgressStep.DELIVERED &&
              track.deliveredOnUtc && (
                <>
                  <div className="text-lg">
                    {deliveredOnUtc.toLocaleString(locale, {
                      weekday: "long",
                      month: "long",
                      day: "numeric",
                    })}{" "}
                    {`${t(
                      "track.deliveredAt"
                    ).toString()} ${deliveredOnUtc.toLocaleString(locale, {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: false,
                    })}`}
                  </div>
                </>
              )}
          </>
        )}
      </div>

      {/* If the package has not been delivered yet, render a not home message */}
      {step < 3 && (
        <div className={""}>
          {t("track.not_home").toString()}&nbsp;
          <span
            className={"text-green font-semibold cursor-pointer"}
            onClick={() => setShowContact(true)}
          >
            {t("track.contact_us").toString()}
          </span>
        </div>
      )}

      <div className={"my-2"}>
        <Progress step={step} />
      </div>

      <Steps status={track.status} step={step} />

      {showContact ? (
        <Overlay blur>
          <Contact />
        </Overlay>
      ) : null}
    </div>
  );
};

export { Track };
