import { useState } from "react";
import { MarkerF, InfoWindowF } from "@react-google-maps/api";
import MarkerIcon from "../../../assets/map-marker-icon.svg";
import HubIcon from "../../../assets/selected-map-hub.svg";

interface MapMarkerProps {
    highlight?: boolean,
    name: string,
    place: string,
    zipcode: string,
    lat: number,
    lng: number,
    selected: boolean,
    onClick?: (...args : any[]) => void,
    clickable?: boolean,
    cursor?: string
}

const MapMarker = ({ highlight = false, name, place, zipcode, lat, lng, selected, onClick, clickable = true, cursor = "pointer" }: MapMarkerProps) => {
    const [showInfoWindow, setShowInfoWindow] = useState(false);

    const handleMouseOver = () => {
        setShowInfoWindow(true);
    };

    const handleMouseExit = () => {
        setShowInfoWindow(false);
    };

    return(
        <MarkerF
            position={{ lat: lat, lng: lng }}
            onClick={onClick}
            icon={selected ? HubIcon : MarkerIcon}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseExit}
            clickable={clickable}
            cursor={cursor}
        >
            {showInfoWindow && (
                <InfoWindowF>
                    <div className={"flex flex-col text-primary gap-1"}>
                        <div className={"font-bold"}>
                            {name}
                        </div>
                        <div className={"text-primary"}>
                            {zipcode} {place}
                        </div>
                    </div>
                </InfoWindowF>
            )}
        </MarkerF>
    )
}

export { MapMarker }
