import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { ProgressStep } from "../index";

interface StatusLabelProps {
    type: ProgressStep
}

const StatusLabel = ({ type }: StatusLabelProps) => {
    const { t } = useTranslation("components");

    return (
        <div className={classNames(
            "flex items-center w-fit text-sm rounded-md p-0.5 px-2.5",
            {
                "bg-info bg-opacity-25": type === ProgressStep.CREATED
            },
            {
                "bg-light-yellow": type === ProgressStep.HUB
            },
            {
                "bg-light-yellow": type === ProgressStep.TRANSIT
            },
            {
                "bg-light-green": type === ProgressStep.DELIVERED
            },
            {
                "bg-info bg-opacity-25": type === ProgressStep.RETURNED
            },
            {
                "bg-light-red": type === ProgressStep.CANCELLED
            }
            )}>
                <div className={classNames(
                    "w-2 h-2 rounded-full",
                    {
                        "bg-info": type === ProgressStep.CREATED
                    },
                    {
                        "bg-yellow": type === ProgressStep.HUB
                    },
                    {
                        "bg-yellow": type === ProgressStep.TRANSIT
                    },
                    {
                        "bg-green": type === ProgressStep.DELIVERED
                    },
                    {
                        "bg-info": type === ProgressStep.RETURNED
                    },
                    {
                        "bg-red": type === ProgressStep.CANCELLED
                    }
                    )}/>
                <div className={"uppercase ml-2 mt-0.5"}>
                    {t("statuslabel." + type.toLowerCase()).toString()}
                </div>
        </div>
    )
}

export { StatusLabel };
