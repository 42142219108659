import i18next from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ShipmentType } from "../../../models/track.dto";
import {
  getLocalisedDate,
  getLocalisedStepDescription,
} from "../../../service/steps";
import {
  getStatusStepByStatus,
  renderStepHeader,
} from "../../../service/track";
import { useStoreState } from "../../../store/hooks";
import { ProgressStep } from "../index";
import { Step } from "./Step";

interface StepsProps {
  status: string;
  step: number;
}

const Steps = ({ status, step }: StepsProps) => {
  const { track } = useStoreState((state) => state.PackageModel);

  const { t } = useTranslation(
    track.shipmentType === ShipmentType.RETOUR ? "track-return" : "track"
  );
  const locale = i18next.language;

  const reversedItems = track.updates?.map((item) => item).reverse();
  const [showSteps, setShowSteps] = useState(3);

  return (
    <div className={"flex flex-col"}>
      {step > 0 && (
        <div className={"w-full bg-light-green p-2 px-4"}>
          <span className={"text-base font-bold"}>
            {renderStepHeader(Object.values(ProgressStep)[step], t)}
          </span>
        </div>
      )}
      {step > 0 && (
        <div className={"w-full text-gray-text mt-4 mb-4"}>
          <span className={"text-base"}>
            {getLocalisedStepDescription(t, Object.keys(ProgressStep)[step])}
          </span>
        </div>
      )}
      <div className={"w-full text-gray-text mb-7 mt-3"}>
        <span className="text-sm">{t("important_message").toString()}</span>
      </div>
      <div className={"flex flex-col"}>
        {reversedItems?.slice(0, showSteps).map((update, index) => {
          //const utcDate = new Date(update.timeOfUpdateUtc); // Date in UTC
          //const date = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);

          const date = getLocalisedDate(new Date(update.timeOfUpdateUtc));

          return (
            <Step
              key={update.id}
              updateType={update.updateType}
              iconType={
                Object.values(ProgressStep)[
                  getStatusStepByStatus(update.updateType)
                ]
              }
              timestamp={`${date.toLocaleTimeString(locale, {
                hour: "2-digit",
                minute: "2-digit",
              })} ${date.toLocaleDateString(locale, {
                weekday: "long",
                month: "long",
                day: "numeric",
              })}`}
              message={update.message}
              subItems={undefined}
              first={index === 0}
              last={
                index + 1 === showSteps || index + 1 === track.updates?.length
              }
            />
          );
        })}
        {reversedItems?.length > showSteps && (
          <button onClick={() => setShowSteps(showSteps + 3)}>
            {t("show_more").toString()}
          </button>
        )}
      </div>
    </div>
  );
};

export { Steps };
