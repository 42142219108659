import { OverlayPropTypes } from "../index";

interface CentralOverlayProps extends OverlayPropTypes {
    outsideClick?: () => void
}

const CentralOverlay = ({ children, outsideClick = () => null }: CentralOverlayProps) => {
    return(
        <>
            <div className={"fixed shadow-xl lg:w-3/4 h-3/5 mx-auto inset-x-0 top-1/2 transform -translate-y-1/2 z-50"}>
                {children}
            </div>
            <div className={"fixed top-0 left-0 w-screen h-screen z-40"} onClick={outsideClick} />
        </>
    );
}

export { CentralOverlay }
