import { TFunction } from "i18next";

export const getLocalisedStepDescription = (t: TFunction, step: string) => {
    if (!step) return "";
    return t(`steps.${step.toLowerCase()}.description`).toString();
}

export const getLocalisedDate = (utcDate: Date) => {
    return new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
}
