import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Input, InputColor } from "../Input";
import { Loader } from "../Loader";
import { Navbar } from "../Menu/Navbar";

interface Custom404Props {
  status?: number;
  message?: string;
  children?: ReactNode;
}

const Custom404 = ({ status, message, children }: Custom404Props) => {
  const { t } = useTranslation("home");
  const navigate = useNavigate();

  const [trackNumber, setTrackNumber] = useState("");
  const [zipcode, setZipcode] = useState("");

  const [errorTrackNumber, setErrorTrackNumber] = useState("");
  const [errorZipcode, setErrorZipcode] = useState("");
  const [loading, setLoading] = useState(false);

  const setTrackHandler = () => {
    setLoading(true);

    if (!trackNumber) {
      setErrorTrackNumber(t("errors.trackNumber").toString());
    }
    if (!zipcode) {
      setErrorZipcode(t("errors.zipcode").toString());
    }
    if (trackNumber && zipcode) {
      // window.open(`/${trackNumber}/${zipcode}`); // Open in new tab
      navigate(`/${trackNumber}/${zipcode}`);
      navigate(0);
    }
    setLoading(false);
  };

  return (
    <div className={"flex flex-col w-full h-full bg-background "}>
      <div className={" grid grid-cols-12 w-full bg-white pb-4"}>
        <div className={"container col-span-12 h-fit"}>
          <Navbar />
        </div>
      </div>

      <div
        className={
          "col-span-12 flex justify-center items-center w-full container "
        }
      >
        <div className={"w-full p-8 mt-8 bg-white max-w-xl "}>
          {children}

          <form onSubmit={(e) => e.preventDefault()}>
            <div
              className={`w-full ${
                errorTrackNumber ? "mb-7" : "mb-2.5"
              } h-12 mt-7`}
            >
              <Input
                placeholder={t("form.trackNumber").toString()}
                color={InputColor.DARK_GREY}
                error={!!errorTrackNumber}
                errorMessage={errorTrackNumber}
                passValueChange={setTrackNumber}
                onChange={() => setErrorTrackNumber("")}
              />
            </div>
            <div className={`w-full ${errorZipcode ? "mb-7" : "mb-2.5"} h-12`}>
              <Input
                placeholder={t("form.zipcode").toString()}
                color={InputColor.DARK_GREY}
                error={!!errorZipcode}
                errorMessage={errorZipcode}
                passValueChange={setZipcode}
                onChange={() => setErrorZipcode("")}
              />
            </div>
            <button
              className={
                "relative flex items-center bg-primary rounded-md cursor-pointer p-3 px-3 w-full"
              }
              onClick={setTrackHandler}
            >
              <p className="text-white text-center w-full">
                {t("errors.retry").toString()}
              </p>
              {loading && (
                <div
                  className={
                    "absolute left-0 w-full h-full rounded-md bg-primary"
                  }
                >
                  <div className={"flex justify-center w-8 h-full mx-auto"}>
                    <Loader />
                  </div>
                </div>
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export { Custom404 };
