import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "./App.css";
import { Custom404 } from "./components/404";
import { DeliveryInstructions } from "./components/DeliveryInstructions";
import { Fallback } from "./components/Fallback";
import { Navbar } from "./components/Menu/Navbar";
import { MultiplePackages } from "./components/MultiplePackages";
import { Toast } from "./components/Toast";
import { Track } from "./components/Track";
import DeliveryDate from "./components/Track/DeliveryDate";
import { Info } from "./components/Track/Info";
import { Search } from "./components/Track/Search";
import FAQPage from "./components/Track/faq";
import { appInsights, reactPlugin } from "./insights/applicationInsights";
import withTracking from "./insights/exportWithTracking";
import { ShipmentType } from "./models/track.dto";
import { fetchTrack } from "./service";
import { useStoreActions, useStoreState } from "./store/hooks";

function Tracker() {
  const { track } = useStoreState((state) => state.PackageModel);

  const { t } = useTranslation(
    track.shipmentType === ShipmentType.RETOUR
      ? "components-return"
      : "components"
  );
  let { id, zipcode } = useParams();

  const started = useRef(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const { setBarcode, setTrack, setZipcode } = useStoreActions(
    (actions) => actions.PackageModel
  );
  // const { setStations, setZipcodeStations } = useStoreActions(actions => actions.StationsModel);

  const { showToast } = useStoreState((store) => store.AppModel);

  useEffect(() => {
    const init = async () => {
      return new Promise<string>(async (resolve, reject) => {
        if (started.current || !id || !zipcode) return;
        started.current = true;

        setBarcode(id);
        setZipcode(zipcode);

        appInsights.trackEvent(
          { name: "Custom event for TrackPage" },
          { Barcode: id, Zipcode: zipcode }
        );

        await fetchTrack(id, zipcode, setTrack)
          .then(() => {
            return resolve("Success!");
          })
          .catch((err) => {
            return reject(err);
          });
        /*await fetchResources(zipcode, setStations, setZipcodeStations)
                    .catch((err) => reject(err.message));*/
      });
    };

    init()
      .then(() => setLoading(false))
      .catch((err) => setError(err));
  }, []);

  useEffect(() => {
    if (error !== "") setLoading(false);
  }, [error]);

  if (loading) return <Fallback />;

  if (error !== "")
    return (
      <Custom404 status={204} message={error}>
        <div className={"flex justify-center mt-5"}>
          <span className={"text-center text-gray-text"}>
            {t("errors.unknown").toString()} {id}, <br /> {zipcode}{" "}
            {t("errors.notFound").toString()}
            {/*{t("errors.retry").toString()}*/}
          </span>
        </div>
      </Custom404>
    );

  return (
    <div
      className={
        "flex flex-col w-full h-full bg-background text-text min-h-screen"
      }
    >
      <div className={"fixed top-0 w-full z-10"}>
        <Toast open={showToast} />
      </div>
      <div className={"grid grid-cols-12 w-full bg-white pb-4"}>
        <div className={"container col-span-12 h-fit"}>
          <Navbar />
        </div>
      </div>
      <div className={"md:container grid grid-cols-12 lg:gap-8 pt-8 mb-12 "}>
        <div className={"col-span-12 lg:col-span-6 bg-white"}>
          <Track />
        </div>
        <div
          className={
            "col-span-12 lg:col-span-6 flex flex-col gap-8 mt-8 lg:mt-0"
          }
        >
          {track.allowedDates && track.allowedDates?.length > 0 && (
            <div className={"bg-white"}>
              <DeliveryDate />
            </div>
          )}

          <div className={"bg-white"}>
            <Info />
          </div>
          <div className={"bg-white"}>
            <DeliveryInstructions />
          </div>

          {track.packages &&
            track.packages?.length > 1 &&
            process.env.REACT_APP_THIS_DISABLES !== "TRUE" && (
              <div className={"bg-white"}>
                <MultiplePackages />
              </div>
            )}

          <div className={"bg-white"}>
            <Search />
          </div>
        </div>
        <div className="col-span-12 gap-8 mt-8">
          <FAQPage />
        </div>
      </div>
    </div>
  );
}

export default withTracking(reactPlugin, Tracker);
