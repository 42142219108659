import SmoothCollapse from "react-smooth-collapse";
import { useStoreState } from "../../store/hooks";
import { ReactComponent as CheckIcon } from "../../assets/check.svg";
import { colors } from "../../service/global/constants";
import classNames from "classnames";

interface ToastProps {
    open: boolean
}

const Toast = ({ open }: ToastProps) => {
    const { toastText, toastColor } = useStoreState(store => store.AppModel);

    return (
        <SmoothCollapse expanded={open}>
            <div className={classNames("flex items-center p-8",
                {
                    "bg-green": toastColor === colors.green
                },
                {
                    "bg-info": toastColor === colors.blue
                },
                {
                    "bg-red": toastColor === colors.red
                },
                {
                    "bg-yellow": toastColor === colors.yellow
                }
                )}
            >
                <CheckIcon fill={colors.white} />
                <div className={"ml-3"}>
                    <span className={"text-white"}>
                        {toastText}
                    </span>
                </div>
            </div>
        </SmoothCollapse>
    );
}

export { Toast };
