import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";
import { useEffect, useState } from "react";
import { MarkerDto } from "../../models/marker.dto";
import { MapMarker } from "./MapMarker";

/*interface MarkerType {
    name: string,
    place: string,
    lat: number,
    lng: number
}*/

interface DefaultMapProps {
  marker: MarkerDto;
  overlay: boolean;
  zoom?: number;
  zoomControl?: boolean;
  mapTypeControl?: boolean;
  scaleControl?: boolean;
  streetViewControl?: boolean;
  rotateControl?: boolean;
  fullscreenControl?: boolean;
  draggableCursor?: string;
}

const containerStyle = {
  width: "100%",
  height: "100%",
};

const DefaultMap = ({
  marker,
  overlay,
  zoom = 13,
  zoomControl = true,
  mapTypeControl = false,
  scaleControl = true,
  streetViewControl = false,
  rotateControl = true,
  fullscreenControl = false,
  draggableCursor = "",
}: DefaultMapProps) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
  });

  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [isReady, setIsReady] = useState(false);

  const getLatLngFromAddress = async () => {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${
        marker.address +
        " " +
        marker.number +
        ", " +
        marker.zipcode +
        " " +
        marker.city
      }&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    );

    const data = response.data;

    if (data.status === "OK") {
      setLat(data.results[0].geometry.location.lat);
      setLng(data.results[0].geometry.location.lng);
      setIsReady(true);
    }
  };

  useEffect(() => {
    getLatLngFromAddress();
  }, []);

  return isLoaded && isReady ? (
    <>
      {overlay ? (
        <div className={"absolute flex w-full h-full pointer-events-auto"} />
      ) : null}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{ lat: Number(lat), lng: Number(lng) }}
        zoom={zoom}
        clickableIcons={false}
        options={{
          zoomControl: overlay ? false : zoomControl,
          mapTypeControl: mapTypeControl,
          scaleControl: scaleControl,
          streetViewControl: streetViewControl,
          rotateControl: rotateControl,
          fullscreenControl: fullscreenControl,
          draggableCursor: draggableCursor,
          keyboardShortcuts: !overlay,
        }}
      >
        <MapMarker
          name={marker.name}
          place={marker.city}
          zipcode={marker.zipcode}
          lat={Number(lat)}
          lng={Number(lng)}
          selected={true}
          cursor={"grab"}
        />
      </GoogleMap>
    </>
  ) : null;
};

export { DefaultMap };
