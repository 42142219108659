import axios from "axios";
import { ActionCreator } from "easy-peasy";
import { StationDto } from "../models/station.dto";
import { TrackDto } from "../models/track.dto";
import { getApiUrl, getShippingApiUrl } from "./api";
import { urls } from "./global/constants";

export const fetchResources = async (
  zipcode: string,
  setStations: ActionCreator<StationDto[]>,
  setZipcodeStations: ActionCreator<StationDto[]>
) => {
  const stationRes = await axios
    .get(getApiUrl() + "User/findstations/" + zipcode, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(async (res) => {
      await setZipcodeStations(res.data);
      return res;
    })
    .catch((err) => Promise.reject(err.message));

  if (stationRes.status !== 200) {
    return Promise.reject("An error occurred");
  }

  const stationsRes = await axios
    .get(getApiUrl() + "User/station", {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(async (res) => {
      await setStations(res.data);
      return res;
    })
    .catch((err) => Promise.reject(err.message));

  if (stationsRes.status !== 200) {
    return Promise.reject("An error occurred");
  }

  return Promise.resolve();
};

export const fetchTrack = async (
  barcode: string,
  zipcode: string,
  setTrack: ActionCreator<TrackDto>
) => {
  return new Promise<string>(async (resolve, reject) => {
    const trackRes = await axios
      .get(
        getShippingApiUrl() + urls.api.trackandtrace + barcode + "/" + zipcode,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (res) => {
        await setTrack(res.data);
        return res;
      })
      .catch((err) => {
        return reject(err.message);
      });

    if (!trackRes) return reject("An error occurred");

    if (trackRes.status === 204) {
      return reject("Unknown barcode");
    }
    if (trackRes.status !== 200) {
      return reject("An error occurred");
    }

    return resolve("Success!");
  });
};

export const postDeliveryInstructions = async (
  barcode: string,
  zipcode: string,
  instructions: string
) => {
  return new Promise<string>(async (resolve, reject) => {
    const trackRes = await axios
      .post(
        getShippingApiUrl() + urls.api.trackandtrace + "extra_instructions/",
        {
          headers: {
            "Content-Type": "application/json",
          },
          orderNumber: barcode,
          zipCode: zipcode,
          notes: instructions,
        }
      )
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        return reject(err.message);
      });

    if (!trackRes) return reject("An error occurred");

    if (trackRes.status === 204) {
      return reject("Unknown barcode");
    }
    if (trackRes.status !== 200) {
      return reject("An error occurred");
    }

    return resolve("Success!");
  });
};
