import axios from "axios";
import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getShippingApiUrl } from "../../../service/api";
import { urls } from "../../../service/global/constants";
import { getStepByStatus } from "../../../service/track";
import { useStoreState } from "../../../store/hooks";
import { DatePicker } from "../../DatePicker";
import { Loader } from "../../Loader";
import { ShipmentType } from "../../../models/track.dto";
import i18next from "i18next";

const DeliveryDate = () => {
  const { track } = useStoreState((state) => state.PackageModel);

  const { t } = useTranslation(track.shipmentType === ShipmentType.RETOUR ? "components-return" : "components"); const locale = i18next.language;

  let { id, zipcode } = useParams();

  const includeDates = track.allowedDates?.map(
    (dateString) => new Date(dateString)
  );

  const startDate = track.preferredDeliveryDate
    ? new Date(track.preferredDeliveryDate)
    : includeDates?.[0];

  const [deliveryDate, setDeliveryDate] = useState(startDate);

  const [loading, setLoading] = useState(false);

  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const disabled = getStepByStatus(track.status) > 0;

  const handleSubmit = async () => {
    if (!deliveryDate) {
      setError(t("delivery_date.errors.missing").toString());
      return;
    }

    setLoading(true);
    setError("");

    await axios
      .patch(getShippingApiUrl() + urls.api.trackandtracedeliverydate, {
        headers: {
          "Content-Type": "application/json",
        },
        newDeliveryDateUtc: deliveryDate.toISOString(),
        orderNumber: id?.toString(),
        zipCode: zipcode?.toString(),
      })
      .then(async (res) => setSuccess(t("delivery_date.success").toString()))
      .catch((err) => setError(t("delivery_date.errors.default").toString()));

    setSuccess(t("delivery_date.success").toString());

    setLoading(false);
  };

  return (
    <div className={"relative flex flex-col p-7"}>
      <div className={"text-xl mb-2"}>
        {t("delivery_date.choose").toString()}
      </div>
      <div className={"text-sm text-gray-text pb-4"}>
        {t("delivery_date.explanation").toString()}
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className={"flex flex-col sm:flex-row"}>
          <div className={"relative flex sm:w-40 h-12 mb-2 sm:mb-0"}>
            {disabled && (
              <div
                className={
                  "absolute w-full h-full bg-white bg-opacity-50 pointer-events-auto cursor-not-allowed z-1"
                }
              />
            )}
            <DatePicker
              value={deliveryDate}
              onChange={(date) => {
                date && setDeliveryDate(date);
              }}
              allowedDates={includeDates}
            />
          </div>
          <button
            className={classNames(
              "relative flex justify-center items-center w-full sm:w-auto h-12 rounded-md p-2 px-7 sm:mx-5",
              {
                "bg-primary cursor-pointer": !disabled,
              },
              {
                "bg-gray-text cursor-not-allowed": disabled,
              }
            )}
            onClick={handleSubmit}
            disabled={disabled}
          >
            <p className="text-white mt-0.5">
              {t("delivery_date.submit").toString()}
            </p>
            {loading && (
              <div
                className={
                  "absolute left-0 w-full h-full rounded-md bg-primary"
                }
              >
                <div className={"flex justify-center w-8 h-full mx-auto"}>
                  <Loader />
                </div>
              </div>
            )}
          </button>
          {success && success !== "" && error === "" && (
            <div className={"flex items-center mt-3 sm:mt-0"}>
              <span className={"text-green"}>{success}</span>
            </div>
          )}
          {error && error !== "" && (
            <div className={"flex items-center mt-3 sm:mt-0"}>
              <span className={"text-red"}>{error}</span>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default DeliveryDate;
