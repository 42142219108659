import axios from "axios";
import matter from "gray-matter-browser";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaqItems } from "./faqItems";

const FAQPage = () => {
  const { i18n } = useTranslation("components");
  const [faqData, setFaqData] = useState<any>([]);

  const fetchFaqData = async () => {
    try {
      const currentLanguage = i18n.language;

      const response = await axios.get(`/faq-${currentLanguage}.yaml`, {
        headers: {
          Accept: "text/html",
        },
      });

      const textContent = response.data;
      const matterResult = matter(textContent);

      if (Array.isArray(matterResult.data)) {
        const faqData = matterResult.data.map((item: any) => {
          return {
            ...item,
            answer: item.answer.replace(/\n/g, "<br />"),
          };
        });
        return faqData;
      } else {
        console.error("FAQ data is not an array:", matterResult.data);
        return null;
      }
    } catch (error) {
      console.error("Error fetching FAQ data:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchFaqData();
      if (data) {
        setFaqData(data);
      }
    };
    fetchData();
  }, []);

  if (!faqData) {
    return <div>Loading...</div>;
  }
  if (faqData.length > 0) {
    return (
      <div className="px-4 md:px-0">
        <p className="text-2xl font-semibold mb-2">FAQ</p>
        {faqData && <FaqItems faqData={faqData} />}
      </div>
    );
  } else {
    return null;
  }
};

export default FAQPage;
