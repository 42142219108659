import { action, Action, thunk, Thunk } from "easy-peasy";
import axios from "axios";
import { getApiUrl } from "../../service/api";
import { StationDto } from "../../models/station.dto";

interface StationsModel {
    stations: StationDto[],
    setStations:  Action<this, StationDto[]>,
    zipcodeStations: StationDto[],
    setZipcodeStations: Action<this, StationDto[]>
    filteredStations: StationDto[],
    setFilteredStations: Action<this, StationDto[]>,
    fetchStations: Thunk<this>,
    getStationsByZipcode: Thunk<this>
}

const StationsModel: StationsModel = {
    stations: [],
    setStations: action((state, payload) => {
        state.stations = payload;
    }),
    zipcodeStations: [],
    setZipcodeStations: action((state, payload) => {
        state.zipcodeStations = payload;
    }),
    filteredStations: [],
    setFilteredStations: action((state, payload) => {
        state.filteredStations = payload;
    }),
    fetchStations: thunk(async (actions) => {
        const data = await axios.get(getApiUrl() + "User/station")
            .then(res => res.data);
        actions.setStations(data);
    }),
    getStationsByZipcode: thunk((actions) => {

    })
}

export default StationsModel;
