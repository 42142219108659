import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { StoreProvider } from "easy-peasy";
import store from "./store";
import Home from "./Home";
import Tracker from "./Tracker";
function App() {
    return (
        <StoreProvider store={store}>
            <Router>
                <Routes>
                    <Route path={"/"} element={<Home/>}/>
                    <Route path={"/:id/:zipcode"} element={<Tracker/>}/>
                </Routes>
            </Router>
        </StoreProvider>
    );
}

export default App;
