import { useTranslation } from "react-i18next";
import { useStoreActions, useStoreState } from "../../store/hooks";

const Contact = () => {
  const { t } = useTranslation("components");

  const { setShowContact } = useStoreActions((actions) => actions.AppModel);
  const { barcode, zipcode } = useStoreState((state) => state.PackageModel);

  return (
    <div className={"flex justify-center items-center w-full h-full"}>
      <div className={"flex flex-col bg-white shadow p-7"}>
        <div className={"flex justify-between w-full mb-1"}>
          <h2 className={"text-xl md:text-2xl mt-0.5"}>
            {t("navbar.contact").toString()}
          </h2>
          <div
            className={"cursor-pointer p-1"}
            onClick={() => setShowContact(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
        <div className="mb-5">
          <p className="max-w-sm leading-[1.2rem] text-base">
            {t("contact.headerDescription").toString()}
          </p>
        </div>
        {/* Contact options */}
        <div className={"flex flex-col text-lg md:text-xl gap-3"}>
          {/* E-mail */}
          <div className={"flex"}>
            <div className={"p-1 mr-16"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.75"
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </div>
            <a
              className={"flex items-center"}
              href={`mailto:customersupport@izipack.nl?subject=Vraag%20over%20mijn%20pakket&body=Hallo%2C%0D%0A%0D%0AIk%20heb%20een%20vraag%20over%20mijn%20pakket%20met%20trackingcode%3A%20https%3A%2F%2Ftrack.izipack.nl%2F${barcode}%2F${zipcode}%0D%0A%0D%0AMet%20vriendelijke%20groet%2C`}
            >
              <div className={"cursor-pointer"}>customersupport@izipack.nl</div>
            </a>
          </div>
          {/* Whatsapp */}
          {/* <div className={"flex"}>
            <div className={"p-1 mr-16"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30.667 30.667"
                className="w-8 h-8"
              >
                <path
                  d="M30.667,14.939c0,8.25-6.74,14.938-15.056,14.938c-2.639,0-5.118-0.675-7.276-1.857L0,30.667l2.717-8.017
                                    c-1.37-2.25-2.159-4.892-2.159-7.712C0.559,6.688,7.297,0,15.613,0C23.928,0.002,30.667,6.689,30.667,14.939z M15.61,2.382
                                    c-6.979,0-12.656,5.634-12.656,12.56c0,2.748,0.896,5.292,2.411,7.362l-1.58,4.663l4.862-1.545c2,1.312,4.393,2.076,6.963,2.076
                                    c6.979,0,12.658-5.633,12.658-12.559C28.27,8.016,22.59,2.382,15.61,2.382z M23.214,18.38c-0.094-0.151-0.34-0.243-0.708-0.427
                                    c-0.367-0.184-2.184-1.069-2.521-1.189c-0.34-0.123-0.586-0.185-0.832,0.182c-0.243,0.367-0.951,1.191-1.168,1.437
                                    c-0.215,0.245-0.43,0.276-0.799,0.095c-0.369-0.186-1.559-0.57-2.969-1.817c-1.097-0.972-1.838-2.169-2.052-2.536
                                    c-0.217-0.366-0.022-0.564,0.161-0.746c0.165-0.165,0.369-0.428,0.554-0.643c0.185-0.213,0.246-0.364,0.369-0.609
                                    c0.121-0.245,0.06-0.458-0.031-0.643c-0.092-0.184-0.829-1.984-1.138-2.717c-0.307-0.732-0.614-0.611-0.83-0.611
                                    c-0.215,0-0.461-0.03-0.707-0.03S9.897,8.215,9.56,8.582s-1.291,1.252-1.291,3.054c0,1.804,1.321,3.543,1.506,3.787
                                    c0.186,0.243,2.554,4.062,6.305,5.528c3.753,1.465,3.753,0.976,4.429,0.914c0.678-0.062,2.184-0.885,2.49-1.739
                                    C23.307,19.268,23.307,18.533,23.214,18.38z"
                />
              </svg>
            </div>
            <a
              className={"flex items-center"}
              target={"_blank"}
              href={`https://wa.me/31851307011?text=Ik%20heb%20een%20vraag%20over%20mijn%20pakket%20met%20trackingcode%3A%20https%3A%2F%2Ftrack.izipack.nl%2F${barcode}%2F${zipcode}`}
              rel="noreferrer"
            >
              <div className={"cursor-pointer"}>
                {t("contact.whatsapp").toString()}
              </div>
            </a>*/}
        </div>
        <p className={"max-w-sm leading-[1.2rem] text-base mt-5"}>
          {t("contact.description").toString()}
        </p>
      </div>
    </div>
  );
};

export { Contact };
