import { createTypedHooks } from "easy-peasy";
import { ModelsInterface } from "./index";

const {
    useStoreState,
    useStoreActions,
    useStoreDispatch,
    useStore,
} = createTypedHooks<ModelsInterface>();

/* Export typed hooks */
export { useStoreState, useStoreActions, useStoreDispatch, useStore };
