import { MenuItems } from "./MenuItems";
import IzipackLogo from "../../../assets/izipack_logo.svg";
import { useTranslation } from "react-i18next";
import { Button, ButtonTypeEnum } from "../../Button";
import { useStoreActions } from "../../../store/hooks";

export interface MenuList {
    text:string,
    link:string,
    subLinkItems?: MenuList[]
}

const Navbar = () => {
    const { t } = useTranslation("components");

    const { setShowContact } = useStoreActions(actions => actions.AppModel);

    return (
        <header>
            <nav className="flex justify-between items-center bg-white pt-5 pr-7 md:pr-0">
                <a className={"pl-6 md:pl-0"} href={"https://izipack.nl"}>
                    <img src={IzipackLogo} alt="Izipack logo"/>
                </a>
                <ul className="hidden md:block">
                    <MenuItems items={[]} />
                </ul>
                <div className={"w-fit"}>
                    <Button
                        buttonType={ButtonTypeEnum.DEFAULT}
                        onClick={() => setShowContact(true)}
                        text={t("navbar.contact").toString()}
                    />
                </div>
            </nav>
        </header>
    )
}

export { Navbar }
