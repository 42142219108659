import classNames from "classnames";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { ReactComponent as CrossIcon } from "../../assets/cross.svg";
import { colors } from "../../service/global/constants";

export enum InputColor {
  WHITE = "white",
  DARK_GREY = "dark-grey",
}

export enum InputType {
  TEXT = "text",
  NUMBER = "number",
}

interface InputProps {
  type?: InputType;
  label?: string;
  placeholder: string;
  color: InputColor;
  validated?: boolean;
  error?: boolean;
  errorMessage?: string;
  showClear?: boolean;
  uppercase?: boolean;
  setInputValue?: string;
  inputValue?: string;
  passValueChange?: (value: any) => void;
  onClick?: () => void;
  onChange?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

export interface InputForwardRefProps {
  setInputValue: (value: string) => void;
}

const Textarea = forwardRef<InputForwardRefProps, InputProps>(
  (
    {
      type = InputType.TEXT,
      placeholder,
      label,
      color,
      validated = false,
      error = false,
      errorMessage = "",
      showClear = false,
      uppercase = false,
      setInputValue,
      inputValue,
      passValueChange,
      onClick,
      onChange,
      onBlur,
      onFocus,
    },
    ref
  ) => {
    const [value, setValue] = useState(inputValue ?? "");

    useImperativeHandle(ref, () => ({
      setInputValue(value: string) {
        setValue(value);
      },
    }));

    useEffect(() => {
      if (passValueChange) passValueChange(value);
    }, [value]);

    const inputRender = (placeholder: string) => (
      <div className={"relative w-full h-full"}>
        <textarea
          className={classNames(
            "peer w-full h-full min-h-[120px] max-h-[120px] text-sm p-2 rounded border border-transparent focus:ring-black focus:ring-transparent outline-2 outline-primary",
            {
              "bg-dark-grey": color === "dark-grey",
            },
            {
              "bg-white": color === "white",
            },
            {
              "scroll-mt-8": label,
            }
          )}
          value={value}
          placeholder={placeholder}
          onClick={onClick}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={(e) => {
            if (uppercase) {
              setValue(e.target.value.toUpperCase());
            } else {
              setValue(e.target.value);
            }

            if (onChange) onChange();
          }}
        />
        {validated && (
          <div
            className={
              "absolute w-full h-full  max-h-[120px] top-0 bg-opacity-50 border-1 border-green rounded pointer-events-none outline-0 outline-transparent"
            }
          />
        )}
        {error && (
          <>
            {/* Red overlay */}
            <div
              className={
                "absolute w-full h-full  max-h-[120px] top-0 bg-red bg-opacity-50 border-1 border-red rounded pointer-events-none outline-0 outline-transparent"
              }
            />
            {/* Error label */}
            <div className={"absolute flex w-full h-fit gap-1"}>
              <div
                className={
                  "flex items-center w-4 h-4 bg-red bg-opacity-25 rounded-full p-[0.3rem] mt-0.5"
                }
              >
                <CrossIcon fill={colors.red} />
              </div>
              <div className={"flex items-center text-red text-xs mt-0.5"}>
                {errorMessage === "" ? "Error message" : errorMessage}
              </div>
            </div>
          </>
        )}
      </div>
    );

    return (
      <div className={"flex flex-col w-full h-full"}>
        {label ? (
          <label className="block text-sm mb-1.5" htmlFor="">
            {label}
          </label>
        ) : null}
        {inputRender(placeholder)}
      </div>
    );
  }
);

export { Textarea };
