import axios from "axios";
import { action, Action, thunk, Thunk } from "easy-peasy";
import { DeliveryPreferences } from "../../components/Track/Preferences";
import { StationDto } from "../../models/station.dto";
import { ShipmentType, TrackDto } from "../../models/track.dto";
import { getApiUrl } from "../../service/api";
import { center } from "../../service/global/constants";

interface PackageModel {
  barcode: string;
  setBarcode: Action<this, string>;
  zipcode: string;
  setZipcode: Action<this, string>;
  delivery: DeliveryPreferences;
  setDelivery: Action<this, DeliveryPreferences>;
  hub: StationDto;
  setHub: Action<this, StationDto>;
  track: TrackDto;
  setTrack: Action<this, TrackDto>;
  fetchTrackAndTrace: Thunk<this, string>;
}

const PackageModel: PackageModel = {
  barcode: "",
  setBarcode: action((state, payload) => {
    state.barcode = payload;
  }),
  zipcode: "",
  setZipcode: action((state, payload) => {
    state.zipcode = payload;
  }),
  delivery: DeliveryPreferences.HOME,
  setDelivery: action((state, payload) => {
    state.delivery = payload;
  }),
  hub: {
    id: -1,
    locationTypeId: -1,
    serviceCountryId: -1,
    serviceAreaId: -1,
    name: "",
    address: "",
    zipcode: "",
    city: "",
    country: "",
    latitude: center.lat,
    longitude: center.lng,
    defaultLocation: false,
    available247: false,
    openingTimes: [],
  },
  setHub: action((state, payload) => {
    state.hub = payload;
  }),
  track: {
    status: "",
    shipmentType: ShipmentType.SHIPMENT,
    estimatedDeliveryWindow: {
      maximum: "",
      minimum: "",
    },
    preferredDeliveryDate: undefined,
    shipmentCreatedUtc: "",
    packages: [],
    shipmentInformation: {
      street: "",
      city: "",
      driver: "",
      name: "",
      zipCode: "",
      reference: "",
      receiverName: "",
      houseNumber: "",
      extraInstructions: "",
    },
    deliveredOnUtc: "",
    updates: [],
    allowedDates: [],
  },
  setTrack: action((state, payload) => {
    state.track = payload;
  }),
  fetchTrackAndTrace: thunk(async (actions, payload) => {
    const data = await axios
      .get(getApiUrl() + "International/trackandtrace?barcodeNumber=" + payload)
      .then((res) => res.data);
    //actions.setTrack(data);
  }),
};

export default PackageModel;
