import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { StationDto } from "../../../models/station.dto";
import { formatZipcode } from "../../../service/global";
import { useStoreState } from "../../../store/hooks";
import { DefaultMap } from "../../Map/defaultMap";
import { Overlay } from "../../Overlay";
import { CentralOverlay } from "../../Overlay/Central";
import { DeliveryPreferences } from "../Preferences";

export const testStation: StationDto = {
  id: -1,
  locationTypeId: -1,
  serviceCountryId: -1,
  serviceAreaId: -1,
  name: "",
  address: "",
  zipcode: "",
  city: "",
  country: "",
  latitude: 52.02,
  longitude: 5.05,
  defaultLocation: false,
  available247: false,
  openingTimes: [],
};

interface InfoProps {
  showQr?: boolean;
}

const Info = ({ showQr = false }: InfoProps) => {
  const { t } = useTranslation("components");
  const [showMapOverlay, setShowMapOverlay] = useState(false);

  const { track, delivery, hub } = useStoreState((store) => store.PackageModel);
  const packageInfo = track.shipmentInformation;
  let { id } = useParams();

  return (
    <div className={"flex flex-col p-7"}>
      {/* Delivery preferences */}
      {/*<Preferences />*/}
      {/* Map */}
      <div
        className={"relative h-64 cursor-pointer mb-6 -mx-7 md:mx-0"}
        onClick={() => setShowMapOverlay(true)}
      >
        {/* <Map center={{ lat: zipcodeStations[0]?.latitude, lng: zipcodeStations[0]?.longitude }}
                     overlay={true} markers={zipcodeStations}
                /> */}
        <DefaultMap
          overlay={true}
          zoomControl={false}
          marker={{
            name: track.shipmentInformation?.name,
            city: track.shipmentInformation?.city,
            zipcode: track.shipmentInformation?.zipCode,
            address: track.shipmentInformation?.street,
            number: track.shipmentInformation?.houseNumber,
          }}
        />
      </div>
      {showMapOverlay && (
        <>
          <div className={"md:hidden"}>
            <Overlay>
              {/* <MapOverlay hubs={zipcodeStations} onExit={() => setShowMapOverlay(false)} /> */}
              <DefaultMap
                overlay={false}
                marker={{
                  name: track.shipmentInformation?.name,
                  city: track.shipmentInformation?.city,
                  zipcode: track.shipmentInformation?.zipCode,
                  address: track.shipmentInformation?.street,
                  number: track.shipmentInformation?.houseNumber,
                }}
              />
            </Overlay>
          </div>
          <div className={"hidden md:block"}>
            <CentralOverlay outsideClick={() => setShowMapOverlay(false)}>
              <div className={"relative w-full h-full"}>
                {/* <Map center={{ lat: zipcodeStations[0]?.latitude, lng: zipcodeStations[0]?.longitude }}
                                     overlay={false} markers={zipcodeStations} /> */}
                <DefaultMap
                  overlay={false}
                  marker={{
                    name: track.shipmentInformation?.name,
                    city: track.shipmentInformation?.city,
                    zipcode: track.shipmentInformation?.zipCode,
                    address: track.shipmentInformation?.street,
                    number: track.shipmentInformation?.houseNumber,
                  }}
                />
                <div
                  className={
                    "absolute flex justify-center items-center top-5 right-5 w-6 h-6 bg-text rounded-full cursor-pointer p-1.5"
                  }
                  onClick={() => setShowMapOverlay(false)}
                >
                  <svg
                    width="16"
                    height="16"
                    className="text-white"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.88525 8.00017L15.609 2.27629C16.1303 1.75496 16.1303 0.912314 15.609 0.390992C15.0877 -0.130331 14.2451 -0.130331 13.7238 0.390992L8 6.11487L2.27624 0.390992C1.75493 -0.130331 0.912295 -0.130331 0.390984 0.390992C-0.130328 0.912314 -0.130328 1.75496 0.390984 2.27629L6.11475 8.00017L0.390984 13.724C-0.130328 14.2454 -0.130328 15.088 0.390984 15.6093C0.650973 15.8693 0.992292 16 1.33361 16C1.67493 16 2.01625 15.8693 2.27624 15.6093L8 9.88546L13.7238 15.6093C13.9838 15.8693 14.3251 16 14.6664 16C15.0077 16 15.349 15.8693 15.609 15.6093C16.1303 15.088 16.1303 14.2454 15.609 13.724L9.88525 8.00017Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </div>
            </CentralOverlay>
          </div>
        </>
      )}
      {/* Package info */}
      <div className={"flex flex-col sm:flex-row gap-4 sm:gap-14"}>
        <div className={"flex flex-col"}>
          <div className={"text-gray-text mb-0.5"}>
            {t("info.package").toString()}:
          </div>
          <div className={"text-sm"}>
            {t("info.reference").toString()}: {id}
          </div>
          {/*<div className={"mb-0.5 mt-5"}>*/}
          {/*    <span className={"text-gray-text"}> {t("info.delivered_at").toString()} : </span> <span*/}
          {/*    className={"text-sm"}>Torenallee 20, 5617 BC Eindhoven</span>*/}
          {/*</div>*/}
          {/*<div className={"text-sm"}>
                        {t("info.courier").toString()}: {packageInfo.driver}
                    </div>
                    <div className={"text-sm"}>
                        {t("info.driver").toString()}: {packageInfo.driver}
                    </div>*/}
        </div>
        <div className={"flex flex-col"}>
          <div className={"text-gray-text mb-0.5"}>
            {t("info.home_address").toString()}:
          </div>
          {/*<div className={"text-sm font-bold"}>
                        {delivery === DeliveryPreferences.HOME ? t("info.home_address").toString() : hub.name}
                    </div>*/}
          <div className={"text-sm font-medium"}>
            {delivery === DeliveryPreferences.HOME
              ? packageInfo?.receiverName
              : hub.name}
          </div>
          <div className={"text-sm"}>
            {delivery === DeliveryPreferences.HOME
              ? `${packageInfo?.street} ${packageInfo?.houseNumber}`
              : hub.address}
            ,
          </div>
          <div className={"text-sm"}>
            {delivery === DeliveryPreferences.HOME
              ? `${packageInfo?.zipCode}, ${packageInfo?.city}`
              : formatZipcode(hub.zipcode) + " " + hub.city}
          </div>
        </div>
      </div>
      {/* QR code */}
      {showQr && (
        <div className={"flex flex-col mt-8"}>
          <div className={"text-gray-text mb-0.5"}>QR code:</div>
          <div className={"text-sm"}>
            Hold this QR code in front of the scanner at the Izipack Station
          </div>
          <div className={"flex flex-col items-center w-full"}>
            <div className={"mt-5"}>
              {/* QR code figure */}
              <div className={"w-56 h-56 bg-primary"} />
            </div>
            <div className={"mt-3 -mb-1"}>451JJ5</div>
          </div>
        </div>
      )}
    </div>
  );
};

export { Info };
