export interface ShipmentInformation {
  street: string;
  city: string;
  driver: string;
  name: string;
  zipCode: string;
  reference: string;
  receiverName: string;
  houseNumber: string;
  extraInstructions: string;
}

export interface TrackUpdate {
  id: number;
  timeOfUpdateUtc: string;
  message: string;
  longitude: number;
  latitude: number;
  updateType: string;
}

export enum ShipmentType {
  SHIPMENT = 'Shipment', RETOUR = 'Retour'
}

export interface TrackPackageStatus {
  labelCode: string,
  status: number
}

export class TrackDto {
  status!: string;
  shipmentType!: ShipmentType;
  estimatedDeliveryWindow!: {
    maximum: string;
    minimum: string;
  };
  preferredDeliveryDate?: Date;
  shipmentCreatedUtc!: string;
  shipmentInformation!: ShipmentInformation;
  deliveredOnUtc!: string;
  packages!: TrackPackageStatus[];
  updates!: TrackUpdate[];
  allowedDates!: string[];
  constructor(data: TrackDto) {
    Object.assign(this, data);
  }
}
