import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ShipmentType } from "../../../../models/track.dto";
import { colors } from "../../../../service/global/constants";
import { renderIcon, renderStepStatusHeader } from "../../../../service/track";
import { useStoreState } from "../../../../store/hooks";
import { ProgressStep } from "../../index";

interface StepItem {
  timestamp: string;
  message: string;
}

interface StepProps extends StepItem {
  iconType: ProgressStep;
  subItems?: StepItem[];
  first?: boolean;
  last?: boolean;
  updateType: string;
}

const Step = ({
  updateType,
  iconType,
  timestamp,
  message,
  subItems,
  first = false,
  last = false,
}: StepProps) => {
  const { track } = useStoreState((state) => state.PackageModel);

  const { t } = useTranslation(
    track.shipmentType === ShipmentType.RETOUR ? "track-return" : "track"
  );

  return (
    <div
      className={classNames("relative flex flex-col gap-3 ", {
        "pt-3": !first,
      })}
    >
      {/* Icon label */}
      <div className={"flex items-center bg-white z-1"}>
        <div className={"w-12 h-12 rounded-full border-black border-2 p-2"}>
          {renderIcon(iconType, colors.green)}
        </div>
        <div className={"text-xl pl-3"}>
          {renderStepStatusHeader(updateType, t)}
        </div>
      </div>
      {/* Text label */}
      {subItems?.map((subItem, index) => (
        <div
          className={"relative bg-dark-grey bg-opacity-75 p-3 pl-16"}
          key={index}
        >
          <div className={"flex flex-col"}>
            <div className={"text-gray-text"}>{subItem.timestamp}</div>
            <div>{subItem.message}</div>
          </div>
          {/* Dot */}
          <div
            className={
              "absolute left-3 top-1/2 w-3 h-3 rounded-full bg-black transform translate-x-1/2 -translate-y-1/2"
            }
          />
        </div>
      ))}
      <div className={"relative bg-dark-grey p-3 pl-16"}>
        <div className={"relative flex flex-col z-2"}>
          <div className={"text-gray-text"}>{timestamp}</div>
          <div>{message}</div>
        </div>
        {/* Dot */}
        <div
          className={
            "absolute left-3 top-1/2 w-3 h-3 rounded-full bg-black transform translate-x-1/2 -translate-y-1/2 z-2"
          }
        />
        {/* Overlay */}
        {last ? (
          <div
            className={"absolute left-0 bottom-0 w-full h-1/2 bg-dark-grey z-1"}
          />
        ) : null}
      </div>
      {/* Bar */}
      <div
        className={
          "absolute left-6 top-0 w-0.5 h-full bg-black transform -translate-x-1/2"
        }
      />
    </div>
  );
};

export { Step };
