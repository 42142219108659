import { Loader } from "../Loader";

const Fallback = () => {
    return (
        <div className={"flex justify-center items-center bg-background min-h-screen"}>
            <div className={"w-16 h-16"}>
                <Loader dark />
            </div>
        </div>
    )
}

export { Fallback }
