import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
interface FaqItem {
  question: string;
  answer: string;
}

interface FaqItemsProps {
  faqData: FaqItem[];
}
const FaqItems: React.FC<FaqItemsProps> = ({ faqData }) => {
  // const renderMarkdownToHtml = (markdown: string) => {
  //   const result = remark().use(breaks).processSync(markdown);
  //   return result.toString();
  // };

  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-x-10  md:gap-y-5">
      {faqData.map((item, index) => (
        <Disclosure as="div" className="mt-2" key={index}>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white px-4 py-2 text-left text-base font-medium text-black hover:bg-gray-100 focus:outline-none focus-visible:ring-0">
                <span>{item.question}</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-gray-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-800 bg-white rounded-b-lg">
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.answer,
                  }}
                />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
};
export { FaqItems };
