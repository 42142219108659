import { action, Action, thunk, Thunk } from "easy-peasy";
import { colors } from "../../service/global/constants";

interface setShowToastParams {
    text: string,
    color: string,
    duration: number
}

interface AppModel {
    showContact: boolean,
    setShowContact: Action<this, boolean>,
    showToast: boolean,
    setShowToast: Action<this, boolean>,
    toastText: string,
    setToastText: Action<this, string>,
    toastColor: string,
    setToastColor: Action<this, string>,
    setToast: Thunk<this, setShowToastParams>
}

const AppModel: AppModel = {
    showContact: false,
    setShowContact: action((state, payload) => {
        state.showContact = payload;
    }),
    showToast: false,
    setShowToast: action((state, payload) => {
        state.showToast = payload;
    }),
    toastText: "",
    setToastText: action((state, payload) => {
        state.toastText = payload;
    }),
    toastColor: colors.green,
    setToastColor: action((state, payload) => {
        state.toastColor = payload;
    }),
    setToast: thunk(async (actions, params) => {
        await actions.setToastText(params.text);
        await actions.setToastColor(params.color);
        await actions.setShowToast(true);
        setTimeout(() => {
            actions.setShowToast(false);
        }, params.duration);
    })
}

export default AppModel;
