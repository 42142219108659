import { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal } from "react";
import { useLockBodyScroll } from "./hooks";
import classNames from "classnames";

export interface OverlayPropTypes {
    blur?: boolean,
    children: string | number | boolean | null | undefined |
        ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal
}

const Overlay = (props: OverlayPropTypes) => {
    useLockBodyScroll();

    return(
        <div className={classNames("fixed w-screen h-screen top-0 left-0 z-50",
            {
                "bg-background": !props.blur,
            },
            {
                "backdrop-blur-[3px]": props.blur,
            })}
        >
            {props.children}
        </div>
    );
}

export { Overlay }
