import React, { useState } from "react";
import SmoothCollapse from "react-smooth-collapse";
import classNames from "classnames";
import { Preference } from "./Preference";
import { useStoreActions, useStoreState } from "../../../store/hooks";
import { capitalize } from "../../../service/global";
import { useTranslation } from "react-i18next";
import { colors } from "../../../service/global/constants";
import { CentralMapOverlay } from "../../Map/MapOverlay/Central";
import { MapOverlay } from "../../Map/MapOverlay";
import { Overlay } from "../../Overlay";

export enum DeliveryPreferences {
    HOME = "HOME", LOCKER = "LOCKER", PICKUP = "PICKUP"
}

const Preferences = () => {
    const { t } = useTranslation("components");

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(DeliveryPreferences.HOME);
    const [confirmed, setConfirmed] = useState(false);

    const [showMapOverlay, setShowMapOverlay] = useState(false);

    const { delivery } = useStoreState(state => state.PackageModel);
    const { stations, zipcodeStations } = useStoreState(state => state.StationsModel);
    const { setDelivery } = useStoreActions(actions => actions.PackageModel);
    const { setToast } = useStoreActions(actions => actions.AppModel);

    const handleSelect = (selected: DeliveryPreferences) => {
        setSelected(selected);

        switch (selected) {
            case DeliveryPreferences.LOCKER:
                setTimeout(() => {
                    setShowMapOverlay(true);
                }, 300);
                return;
            case DeliveryPreferences.PICKUP:
                setTimeout(() => {
                    setShowMapOverlay(true);
                }, 300);
                return;
        }
    };

    return (
        <div className={classNames("relative flex flex-col w-full pb-12")}>
            <div className={"relative flex flex-col w-fit"}>
                <div className={"text-gray-text"}>
                    {t("preferences.preferences").toString()}:
                </div>
                <div className={"absolute top-6 flex justify-between w-full"}>
                    <div>
                        {capitalize(delivery)}
                    </div>
                    <div className={classNames("group cursor-pointer",
                        {
                            "cursor-not-allowed": confirmed
                        })}
                         onClick={() => !confirmed ? setOpen(true) : null}
                    >
                        <span className={classNames("text-green font-semibold",
                            {
                                "group-hover:underline": !confirmed
                            })}
                        >
                            {t("preferences.change").toString()}:
                        </span>
                    </div>
                </div>
            </div>

            <SmoothCollapse expanded={open} onChangeEnd={() => !open ? setSelected(delivery) : null} allowOverflowWhenOpen>
                <div className={"relative flex flex-col bg-white"}>
                    <div className={"flex flex-col mb-10"}>
                        {Object.keys(DeliveryPreferences).map((preference, index) => (
                            <div key={index}>
                                <Preference
                                    type={preference as DeliveryPreferences}
                                    last={index === Object.keys(DeliveryPreferences).length - 1}
                                    selected={selected} setSelected={handleSelect}
                                />
                            </div>
                        ))}
                    </div>
                </div>

                <div className={"flex justify-center w-full text-sm gap-5 -mb-5"}>
                    <div className={"group cursor-pointer"} onClick={() => setOpen(false)}>
                            <span className={"font-bold group-hover:underline"}>
                                {t("preferences.cancel").toString()}
                            </span>
                    </div>
                    <div className={"group cursor-pointer"} onClick={() => {
                        setOpen(false);
                        setDelivery(selected);
                        setConfirmed(true);
                        setTimeout(() => {
                            setToast({
                                text: t("preferences.success").toString(),
                                color: colors.green,
                                duration: 2000
                            });
                        }, 800);
                    }}>
                            <span className={"text-green font-bold group-hover:underline"}>
                                {t("preferences.confirm").toString()}
                            </span>
                    </div>
                </div>
            </SmoothCollapse>

            {showMapOverlay && (
                <>
                    <div className={"md:hidden"}>
                        <Overlay>
                            <MapOverlay info={t("map.choose").toString()} onExit={() => setShowMapOverlay(false)} hubs={zipcodeStations} enableSelect />
                        </Overlay>
                    </div>
                    <div className={"hidden md:block"}>
                        <CentralMapOverlay onExit={() => setShowMapOverlay(false)} hubs={zipcodeStations} />
                    </div>
                </>
            )}
        </div>
    );
}

export { Preferences };
