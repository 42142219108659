import nl from "date-fns/locale/nl";
import Picker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface DatePickerProps {
  value: Date;
  allowedDates?: Date[];
  onChange?: (date: Date | null) => void;
}

registerLocale("nl", nl);

const DatePicker = ({ value, onChange, allowedDates }: DatePickerProps) => {
  return (
    <Picker
      selected={value}
      onChange={(date) => onChange && onChange(date)}
      includeDates={allowedDates}
      locale="nl"
      customInput={
        <button
          className={
            "flex justify-center items-center w-full h-12 bg-light-grey rounded-md px-8 py-2 z-10"
          }
        >
          <span>{value.toLocaleDateString()}</span>
        </button>
      }
    />
  );
};

export { DatePicker };
