import { createStore } from 'easy-peasy';
import StationsModel from "./models/stations";
import PackageModel from "./models/package";
import AppModel from "./models/app";

export interface ModelsInterface {
    StationsModel: StationsModel,
    PackageModel: PackageModel,
    AppModel: AppModel
}

const models = {
    StationsModel,
    PackageModel,
    AppModel
}

const store = createStore<ModelsInterface>(models);

export default store;
