interface LoaderProps {
    dark?: boolean,
}

const Loader = ({ dark = false }: LoaderProps) => {
    return(
        <svg
            viewBox={"0 0 38 38"}
            className={"animate-spin"}
            xmlns={"http://www.w3.org/2000/svg"}
        >
            <g fill={"none"} fillRule={"evenodd"}>
                <g transform={"translate(1 1)"} strokeWidth={"2"}>
                    <circle stroke={dark ? "black" : "white"} strokeOpacity={".5"} cx={"18"} cy={"18"} r={"18"} />
                    <path stroke={dark ? "black" : "white"} d={"M36 18c0-9.94-8.06-18-18-18"}>
                        <animateTransform
                            attributeName={"transform"}
                            type={"rotate"}
                            from={"0 18 18"}
                            to={"360 18 18"}
                            dur={"1s"}
                            repeatCount={"indefinite"}
                        />
                    </path>
                </g>
            </g>
        </svg>
    )
}

export { Loader }
