import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { postDeliveryInstructions } from "../../service";
import { useStoreState } from "../../store/hooks";
import { InputColor } from "../Input";
import { Loader } from "../Loader";
import { Textarea } from "../Textarea";
import { ShipmentType } from "../../models/track.dto";
import i18next from "i18next";

const DeliveryInstructions = () => {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const { track } = useStoreState((state) => state.PackageModel);
  let { id, zipcode } = useParams();

  const [error, setError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFirst, setIsFirst] = useState(
    track.shipmentInformation?.extraInstructions ? true : false
  );
  const [deliveryInstructions, setDeliveryInstructions] = useState(
    track.shipmentInformation?.extraInstructions
  );

  const { t } = useTranslation(
    track.shipmentType === ShipmentType.RETOUR ? "track-return" : "track"
  );
  const locale = i18next.language;

  const handleDeliveryInstructions = async () => {
    setLoading(true);
    setIsFirst(false);
    // if (deliveryInstructions === "") {
    //   const errorText = t("errors.delivery_instructions_error");
    //   setError(true);
    //   setErrorMessage(errorText);
    //   setLoading(false);
    //   return;
    // }

    if (deliveryInstructions.length > 300) {
      const errorText = t("errors.delivery_instructions_error_count");
      setError(true);
      setErrorMessage(errorText);
      setLoading(false);
      return;
    }
    if (!id || !zipcode) {
      setValidated(false);
    } else {
      setValidated(true);
      await postDeliveryInstructions(id, zipcode, deliveryInstructions);
      setDone(true);
    }
    setLoading(false);
  };

  return (
    <div className={"relative flex flex-col p-7"}>
      <p className={"text-sm pb-2"}>{t("delivery_instructions")}</p>

      {done && (
        <div className={"bg-light-green px-3 py-2 mb-3 text-sm"}>
          {t("delivery_instructions_succeed")}
        </div>
      )}
      <Textarea
        error={error}
        errorMessage={errorMessage}
        validated={validated}
        inputValue={track.shipmentInformation?.extraInstructions}
        placeholder={t("delivery_instructions_text").toString()}
        color={InputColor.DARK_GREY}
        passValueChange={setDeliveryInstructions}
        onChange={() => {
          setError(false);
          setErrorMessage("");
          setDone(false);
          setIsFirst(false);
          setValidated(false);
        }}
      />
      <button
        className={`relative md:w-[150px] sm:w-auto h-12 sm:h-auto ${
          done || isFirst ? "bg-grey" : "bg-primary"
        } rounded-md cursor-pointer ${error ? "mt-8" : "mt-3"}`}
        disabled={loading || done || isFirst}
        onClick={() => {
          handleDeliveryInstructions();
        }}
      >
        {loading ? (
          <div className={"flex justify-center w-5 h-5 mx-auto"}>
            <Loader />
          </div>
        ) : (
          <p className="text-white">{t("submit_delivery_button").toString()}</p>
        )}
      </button>
    </div>
  );
};

export { DeliveryInstructions };
