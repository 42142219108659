import { Input, InputColor } from "../../Input";
import { ReactComponent as SearchIcon } from "../../../assets/search.svg";
import { colors, urls } from "../../../service/global/constants";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { validateBarcode, validateZipcode } from "../../../service/track";
import { Loader } from "../../Loader";
import axios from "axios";
import { getShippingApiUrl } from "../../../service/api";

const Search = () => {
    const { t } = useTranslation("components");

    const [barcodeInput, setBarcodeInput] = useState("");
    const [zipcodeInput, setZipcodeInput] = useState("");
    const [error, setError] = useState("");

    const [loading, setLoading] = useState(false);

    const handleSearch = async () => {
        if (!validateBarcode(barcodeInput)) {
            setError(t("search.errors.invalid_barcode").toString());
            if (!validateZipcode(zipcodeInput)) {
                setError(t("search.errors.invalid_barcode").toString() +
                    ` ${t("search.and").toString()} ` +
                    t("search.errors.invalid_zipcode").toString().toLowerCase());
                return;
            }
            return;
        }
        if (!validateZipcode(zipcodeInput)) {
            setError(t("search.errors.invalid_zipcode").toString());
            return;
        }

        setLoading(true);
        setError("");


        var shippingUrl = getShippingApiUrl();

        await axios.get(getShippingApiUrl() + urls.api.trackandtrace + barcodeInput + "/" + zipcodeInput, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(async (res) => window.open(`/${barcodeInput}/${zipcodeInput}`, "_self"))
            .catch((err) => setError(t("search.errors.not_found").toString()));

        setLoading(false);
    };

    return (
        <div className={"relative flex flex-col p-7"}>
            <div className={"text-sm pb-2"}>
                {t("search.find").toString()}
            </div>
            <form onSubmit={e => e.preventDefault()}>
                <div className={"flex flex-col sm:flex-row"}>
                    <div className={"flex w-full sm:w-fit h-12 gap-2 mb-2 sm:mb-0"}>
                        <div className={"w-[70%]"}>
                            <Input
                                placeholder={t("search.add").toString()} color={InputColor.DARK_GREY}
                                passValueChange={setBarcodeInput} onChange={() => setError("")}
                            />
                        </div>
                        <div className={"w-[30%]"}>
                            <Input
                                placeholder={"Zipcode"} color={InputColor.DARK_GREY}
                                passValueChange={setZipcodeInput} onChange={() => setError("")}
                            />
                        </div>
                    </div>
                    <button
                        className={"relative flex justify-center items-center w-full sm:w-auto h-12 sm:h-auto bg-primary rounded-md cursor-pointer p-2 pl-3 pr-4 sm:ml-5 my-0.5"}
                        onClick={handleSearch}
                    >
                        <SearchIcon fill={colors.white} />
                        <p className="text-white mt-0.5 ml-2">
                            {t("search.search").toString()}
                        </p>
                        {loading && (
                            <div className={"absolute left-0 w-full h-full rounded-md bg-primary"}>
                                <div className={"flex justify-center w-8 h-full mx-auto"}>
                                    <Loader />
                                </div>
                            </div>
                        )}
                    </button>
                </div>
            </form>
            {/* Error */}
            <div className={"absolute flex bottom-1 break-words"}>
                <span className={"text-sm text-red"}>
                    {error}
                </span>
            </div>
        </div>
    );
}

export { Search };
